import React, { useState, useEffect } from "react"
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs, getPageUrlOutOfSanityReference,
  mapEdgesToNodes
} from "../lib/helpers";

import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import CoursePage from "../components/coursepage";
import { toPlainText } from "../lib/helpers";
import AuthenticatorCompleteProfile from "../components/auth0/AuthenticatorCompleteProfile";
import Authenticator from "../components/auth0/Authenticator";

export const query = graphql`
query CoursePageTemplateQuery($id: String!) {
  post: sanityCourse(id: {eq: $id}) {
    id
    _id
    title
    subtitle
    courseName
    courseColour
    slug {
      current
      _type
      _key
    }
    mailUrl
    whatsappUrl
    pinterestUrl
    linkedinUrl
    twitterUrl
    facebookUrl
    _type
    enableOpenCourse
    theme
    sponsor {
      _key
      _type
      courseSponsorName
      courseSponsorLink
      _rawCourseSponsorImg(resolveReferences: {maxDepth: 10})
      _rawCourseSponsorImgDark(resolveReferences: {maxDepth: 10})
    }
    subfolder {
      folder
    }
    redirectPage {
          ... on SanityInformation {
            _type
            slug {
              current
            }
            subfolder {
                  folder
             }
          }
          ... on SanityPost {
            _type
            slug {
              current
            }
          }
          ... on SanityQuestionPost {
            id
            _type
            slug {
              current
            }
          }
          ... on SanityLinkCategory {
            id
            url
            _type
          }

          ... on SanityLandingPage {
                id
                subfolder {
                  folder
                }
                slug {
                  current
                }
                _type
              }
        }
    _rawExcerpt(resolveReferences: { maxDepth: 5 })
    content {
      ... on SanitySmallSpacerBlock {
        _key
        _type
      }
      ... on SanityLesson {
        _key
        _type
        title
        lessonTitle
        lessonID {
           current
        }
        lessonItems {
          _rawIntroPara(resolveReferences: {maxDepth: 10})
          title

        }
      }
    }

  }
}
`;

const CoursePageTemplate = (props) => {
    const { data, errors } = props;

    const post = data && data.post;
    const getPageUrl = getPageUrlOutOfSanityReference(post)

  var isOpenCourse = data.post.enableOpenCourse;
  var theme = data.post.theme;

  var isCoursePage = true;

  if (theme =="Boring Money theme") {
    isCoursePage = false;
  }

  //console.log("post.enableOpenCourse", post.enableOpenCourse)

  var privatePage = false;
  if (!post.enableOpenCourse) {
    privatePage = true;
  }

  var auth = true;
  if (!post.enableOpenCourse) {
    auth = Authenticator();
  }

  const [getToken, setToken] = useState(typeof window !== "undefined" ? localStorage.getItem("id_token") : []);
  const [getProfile, setProfile] = useState(typeof window !== "undefined" ? localStorage.getItem("profile") : []);

  const [getProfileSet, setProfileSet] = useState(null);
  const [getPage, setPage] = useState(null)

  useEffect(() => {
    const userProfile = localStorage.getItem('profile');
    const loggedIn = localStorage.getItem('id_token');

    setToken(loggedIn);

    var isProfileSet = (String(userProfile).toLowerCase() === 'true')

    setProfileSet(isProfileSet);
    setPage(window.location.pathname);
  }, []);

  if (getProfileSet === null) {
    // You might show a loading state here while checking the authentication
    return null;
  }

  if (auth) {
  try {
    return (
      <AuthenticatorCompleteProfile getProfileSet={getProfileSet} fromPage={getPage} getToken={getToken} privatePage={privatePage}>
      <Layout isCoursePage={isCoursePage}>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.title || "Boring Money"}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
            canonical={getPageUrl}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <CoursePage {...post} isCoursePage={isCoursePage}/>}
        </Layout>
      </AuthenticatorCompleteProfile>
    );
  } catch (e) {
    console.log("CoursePageTemplate", e);
    return (null);
  }
  }
  else {
    return null;
  }
};

export default CoursePageTemplate;
